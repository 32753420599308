import { useRuntimeConfig } from "./runtimeConfig"

const useGetSevicePhoneNumbers = () => {
  const { getRuntimeConfigByKey } = useRuntimeConfig()
  const SERVICE_CENTER_PHONE_NUMBERS =
    getRuntimeConfigByKey("SERVICE_CENTER_PHONE_NUMBERS") || ""
  const [
    CUSTOMER_SERVICE_PHONE_NUMBER,
    INTERNATIONAL_CUSTOMER_SERVICE_PHONE_NUMBER,
  ] = SERVICE_CENTER_PHONE_NUMBERS.split(";")

  if (
    !CUSTOMER_SERVICE_PHONE_NUMBER ||
    !INTERNATIONAL_CUSTOMER_SERVICE_PHONE_NUMBER
  ) {
    console.warn(
      "SERVICE_CENTER_PHONE_NUMBERS has a wrong format - ",
      SERVICE_CENTER_PHONE_NUMBERS
    )
  }
  return {
    CUSTOMER_SERVICE_PHONE_NUMBER,
    INTERNATIONAL_CUSTOMER_SERVICE_PHONE_NUMBER,
  }
}
export default useGetSevicePhoneNumbers
