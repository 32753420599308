import React, { useEffect } from "react"
import { useTranslations } from "next-intl"
import Image from "next/image"
import * as Sentry from "@sentry/nextjs"

import { useTracking } from "../../hooks/tracking"
import { KnownPages } from "../../types"
import { Box, Typography } from "../_common"
import icon from "./icons/maintenance.png"
import SimpleNavBar from "../NewNavigation/SimpleNavBar"
import ServiceCenterPhoneNumber from "../ServiceCenterPhoneNumber"
import classes from "./Maintenance.module.scss"

interface IMaintenanceProps {
  error: Error & { digest?: string }
}

const Maintenance = ({ error }: IMaintenanceProps) => {
  const translate = useTranslations()
  const { trackEvent } = useTracking()

  useEffect(() => {
    Sentry.captureException(error)
    trackEvent({ page: KnownPages.SERVER_ERROR, method: "page" })
  }, [error, trackEvent])

  return (
    <>
      <SimpleNavBar
        withLink={false}
        containerClassName={classes.navBarContainer}
      />
      <Box className={classes.container}>
        <Image className={classes.icon} src={icon} alt="" />

        <Typography component="p" className={classes.title}>
          {translate("errorPage.title")}
        </Typography>
        <Typography component="p" className={classes.description}>
          {translate.rich("errorPage.description", {
            break: () => <br />,
          })}
        </Typography>
        <Typography component="p" className={classes.credentials}>
          {translate("errorPage.credentials.title")}
        </Typography>
        <Box className={classes.credentials}>
          <Box>
            {translate.rich("errorPage.credentials.mail", {
              mailLink: (text) => (
                <a href={`mailto:${translate("emailAddress")}`}>{text}</a>
              ),
            })}
          </Box>
          <Box component="span" className={classes.divider}>
            |
          </Box>
          <Box>
            {translate.rich("errorPage.credentials.dachPhone", {
              phoneLink: () => <ServiceCenterPhoneNumber isLink />,
            })}
          </Box>
          <Box component="span" className={classes.divider}>
            |
          </Box>
          <Box>
            {translate.rich("errorPage.credentials.internationalPhone", {
              intlPhoneLink: () => (
                <ServiceCenterPhoneNumber isLink phoneType="international" />
              ),
            })}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Maintenance
