import React from "react"
import clsx from "clsx"
import { Logo, MuiAppBar, Container, Box } from "../_common"
import { KnownPages } from "../../types"
import useWithDomRef from "../../hooks/domRefs/useWithDomRef"
import classes from "./SimpleNavBar.module.scss"

interface ISimpleNavBarProps {
  page?: KnownPages
  /**
   * Not rendered on screens < 600px (sm).
   * Parent compoenent is responsible to display info if necessary
   */
  rightSlotContent?: React.ReactNode
  withDOIBanner?: boolean
  containerClassName?: string
  withLink?: boolean
  children?: React.ReactNode
}

const SimpleNavBar: React.FC<ISimpleNavBarProps> = ({
  rightSlotContent,
  containerClassName,
  withLink = true,
  children,
}) => {
  const navBarRef = useWithDomRef("navbar")

  return (
    <MuiAppBar
      className={classes.appBar}
      elevation={0}
      position="static"
      color="primary"
      ref={navBarRef}
    >
      <Container className={clsx(classes.container, containerClassName)}>
        <Box className={classes.logoWrapper}>
          <Logo sizeVariation="full" withLink={withLink} />
        </Box>

        {rightSlotContent && (
          <Box className={classes.rightSlotWrapper}>{rightSlotContent}</Box>
        )}

        {children}
      </Container>
    </MuiAppBar>
  )
}

export default SimpleNavBar
