"use client"

import React from "react"
import clsx from "clsx"
import { AdviqoLink } from "../_common"
import useGetSevicePhoneNumbers from "../../hooks/useGetSevicePhoneNumbers"
import classes from "./ServiceCenterPhoneNumber.module.scss"

interface IServiceCenterPhoneNumberProps {
  className?: string
  isLink?: boolean
  /**
   * won't apply any styles to the link if no className prop provided
   * `false` by default
   */

  useOnlyOuterStylingOrPlain?: boolean
  phoneType?: "local" | "international"
  testId?: undefined | string
}

const ServiceCenterPhoneNumber: React.FC<IServiceCenterPhoneNumberProps> = ({
  className = undefined,
  isLink = true,
  useOnlyOuterStylingOrPlain = false,
  phoneType = "local",
  testId = undefined,
}) => {
  const {
    CUSTOMER_SERVICE_PHONE_NUMBER,
    INTERNATIONAL_CUSTOMER_SERVICE_PHONE_NUMBER,
  } = useGetSevicePhoneNumbers()
  const telToShow =
    phoneType === "local"
      ? CUSTOMER_SERVICE_PHONE_NUMBER
      : INTERNATIONAL_CUSTOMER_SERVICE_PHONE_NUMBER

  return (
    <>
      {isLink ? (
        <AdviqoLink
          to={`tel:${telToShow}`}
          target="_self"
          className={
            useOnlyOuterStylingOrPlain
              ? className
              : clsx(classes.telLink, className)
          }
          data-testid={testId}
        >
          {telToShow}
        </AdviqoLink>
      ) : (
        telToShow
      )}
    </>
  )
}

export default ServiceCenterPhoneNumber
