import { RefObject, useEffect, useRef } from "react"
import { TComponentsReferences } from "../../types"
import useDomRefs from "./useDomRefs"

const useWithDomRef = (
  name: TComponentsReferences
): RefObject<HTMLDivElement> => {
  const ref = useRef<HTMLDivElement>(null)
  const { addRef, delRef } = useDomRefs()

  useEffect(() => {
    if (ref.current) {
      addRef(name, ref)
    }

    return () => {
      delRef(name)
    }
  }, [addRef, delRef, name])

  return ref
}

export default useWithDomRef
