"use client"

import { ComponentProps } from "react"
import Maintenance from "../ui-lib/Maintenance/Maintenance"

export default function GlobalError({
  error,
}: Pick<ComponentProps<typeof Maintenance>, "error">) {
  return (
    <html>
      <body>
        <Maintenance error={error} />
      </body>
    </html>
  )
}
